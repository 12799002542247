<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Pejabat Baru</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="code">Nama Pegawai<strong>*</strong></label>
                  <v-select :options="dataPegawai" :reduce="code => code.code" label="label" v-model="form.pegawai_id" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <label for="name">Autorisasi<strong>*</strong></label>
                  <v-select :options="dataAutorisasi" v-model="form.autorisasi" class="style-chooser">
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3" v-if="form.autorisasi == 'Pejabat Pelaksana Teknis Kegiatan' || form.autorisasi == 'Bendahara'">
                  <label for="nip">Anggaran</label>
                  <v-select :options="optAnggaran" :reduce="code => code.id" label="nama_rekening" v-model="form.anggaran_id" class="style-chooser"></v-select>
                </b-col>
                <b-col md="12" class="form-group col-sm-6">
                  <label class="d-block">Status</label>
                  <input type="text" class="form-control" id="mak" v-model="form.status_aktif" readonly>
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="formSave()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDataService, putDataService } from '../../../store/modules/crudservices'

export default {
  name:'PejabatEdit',
  data() {
    return {
      optAnggaran:[],
      dataPegawai:[],
      dataAutorisasi:["Petugas Tanda Tangan", "Pejabat Pelaksana Teknis Kegiatan", "Sekretaris", "Bendahara"],
      form:[],
      // loaded: false,
      anggaran_id: null
    }
  },
  methods: {
    getUser() {
      const url = '/pegawai-search?filter=all';
      getDataService(url).then(response => {
        this.dataPegawai = response.data
      })
    },
    getAnggaran() {
      const url = '/anggaran-search';
      getDataService(url).then(response => {
        this.optAnggaran = response.data
      })
    },
    getDataPejabat(id) {
      const url = '/pejabat/' + id;
      getDataService(url).then(response => {
        this.form = response.data
        this.anggaran_id = response.data.anggaran_id
      })
    },
    formSave(){
      const url = `/pejabat/${this.form.id}`
      const form = this.form
      const _data = {
        "_method": "PUT",
        "pegawai_id": form.pegawai_id,
        "autorisasi": form.autorisasi,
        "anggaran_id": form.autorisasi == 'Pejabat Pelaksana Teknis Kegiatan' ? form.anggaran_id : null
      }
      
      putDataService(url, _data).then(response => {
        if(response.data.success) this.$router.push('/master/pejabat')
      })
    },
    formCancel(){
      this.$router.push('/master/pejabat') 
    },
  },
  mounted() {
    this.getDataPejabat(this.$route.params.id)
    setTimeout(() => {
      this.getUser()
      this.getAnggaran()
    }, 500);
  }
}
</script>